<template>
    <a-layout class="CustomerExperienceAdmin">
        <a-layout>
            <a-layout-header class="app-header">
                <div class="logo">
                    <a href="/"><img src="../assets/images/ic_logo_white.png" alt=""></a>
                </div>
                <a-menu
                        theme="dark"
                        mode="horizontal"
                        :defaultSelectedKeys="[this.$route.path]"
                        :style="{ lineHeight: '64px' }"
                >
                    <a-menu-item key="/dashboard">
                        <router-link :to="{ path: '/dashboard' }" class="nav-link">
                            <a-icon type="dashboard" />
                            <span>Dashboard</span>
                        </router-link>
                    </a-menu-item>
                    <a-sub-menu>
                        <span slot="title" class="submenu-title-wrapper"><a-icon type="question-circle" />Báo cáo</span>
                        <a-menu-item key="/bao-cao-ti-le-test">
                            <router-link :to="{ path: '/bao-cao-ti-le-quet' }" class="nav-link">
                                <span>Tỉ lệ quét bill</span>
                            </router-link>
                        </a-menu-item>
                    </a-sub-menu>
                </a-menu>
                <div class="header-right">
                    <a-dropdown>
                        <a-menu slot="overlay">
                            <a-menu-item @click="logout" key="3"><a-icon type="user" />Đăng xuất</a-menu-item>
                        </a-menu>
                        <a-button style="margin-left: 8px">Xin chào <strong style="margin: 0 10px">{{ getUser.fullName }}</strong> <a-icon type="down" />
                        </a-button>
                    </a-dropdown>
                </div>
            </a-layout-header>
            <router-view></router-view>
        </a-layout>
    </a-layout>
</template>

<script>

    import { mapGetters, mapActions } from 'vuex';

    export default {
        name: "CustomerExperienceAdmin",
        data() {
            return {
                collapsed: false
            };
        },
        computed: {
            ...mapGetters('authenticate', [
                'getUser'
            ])
        },
        methods: {
            logout: async () => {
                // eslint-disable-next-line no-console,no-undef
                await ssoLogout()
            },
            ...mapActions('staticData', [
                'setRemoteCategories'
            ])
        },
        async created() {
            await this.setRemoteCategories();
        }
    };
</script>

<style lang="scss">
    .CustomerExperienceAdmin {
        background-color: #31343d;

        .trigger {
            font-size: 18px;
            line-height: 64px;
            padding: 0 24px;
            cursor: pointer;
            transition: color .3s;
        }

        .app-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 25px;
        }

        .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-selected,
        .ant-menu.ant-menu-dark .ant-menu-item.ant-menu-item-actived {
            background-color: transparent;
        }

        .header-right{
            text-align: right;
            width: 100%;
        }

        .logo img {
            height: 58px;
            margin-bottom: 10px;
            margin-right: 30px;
        }
    }
</style>
